import React from 'react';
import FoldContainer from '../components/common/FoldContainer';
import SectionTitle from '../components/common/SectionTitle';
import Layout from '../components/Layout/Layout';
import styled from 'styled-components';

interface Props {}

const Text = styled.div`
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
`;

const Rules: React.FC<Props> = () => {
  return (
    <Layout>
      <FoldContainer>
        <SectionTitle backgroundText="rules">Rules of Participation</SectionTitle>
        <Text>
          We will outline the virtual roundtable discussion rules of engagement to ensure the vision
          for this symposium is achieved. The goal is to maximize a discussion without interruptions
          or monologue by any individual faculty discussant.
          <br />
          <br />
          1. Faculty discussants will be granted five minutes for topic presentation.
          <br />
          2. Moderators will lead question and answer session for next five minutes.
          <br />
          3. Non-faculty registered members of the audience will be able to listen and submit
          questions to the moderators to prompt expanded discussion.
          <br />
          4. Other faculty discussants will be able to engage the presenting faculty directly in
          dialogue.
          <br />
          5. No topic will continue beyond ten minutes, at which point the group moves to next
          discussant.
        </Text>
      </FoldContainer>
    </Layout>
  );
};

export default Rules;
